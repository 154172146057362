import { Select } from "components/Forms/Select";
import useAuth from "hooks/useAuth";
import { PROVIDER_STATUS, ProviderItem } from "models/provider";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getAllProviders } from "services/providerService";
import { queryClient } from "services/queryClient";

export const PROVIDER_SELECT_QUERY = "all-providers";

export const ProviderFilter = () => {
  const { user, selectedProvider, handleSelectProvider } = useAuth();
  const { t, i18n } = useTranslation();

  const { data: providers } = useQuery(
    PROVIDER_SELECT_QUERY,
    () => getAllProviders({ status: PROVIDER_STATUS.Ativo }),
    {
      enabled: user?.role === "admin",
      refetchInterval: false,
      staleTime: Infinity,
    }
  );

  const providerItems: ProviderItem[] = useMemo(() => {
    if (providers) {
      return [
        { id: "admin", name: t("filters.admin"), currency: "BRL", averageTicket: 0 },
        ...providers.map((provider) => ({
          id: provider.id,
          name: provider.name,
          currency: provider.currency ?? "BRL",
          averageTicket: provider.averageTicket ?? 0,
        })),
      ];
    }
    return [];
  }, [providers, i18n.language]);

  const handleChangeSelectedProvider = (providerId: string | undefined) => {
    const provider = providerItems.find(
      (provider) => provider.id === providerId
    );

    if (provider) {
      handleSelectProvider(provider);
      queryClient.invalidateQueries();
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <p className="text-base">{t("filters.provider_select")}</p>
      <Select
        onChange={handleChangeSelectedProvider}
        options={providerItems.map((provider) => ({
          label: provider.name,
          value: provider.id,
        }))}
        selected={selectedProvider.id}
        variant="secondary"
      />
    </div>
  );
};
