import { TFunction } from "i18next";
import { BoardTag } from "./board";

export type Provider = {
  id: string;
  email: string;
  name: string;
  client: string;
  whatsapp: string;
  responsible: string;
  status: number;
  date: string;
  leadsStatus: LeadStatus[];
  averageTicket: number | null;
  currency: string | null;
};

type LeadStatus = {
  id: string;
  name: string;
  tag?: BoardTag;
};

export type ProviderItem = {
  id: string;
  name: string;
  currency: string;
  averageTicket: number;
};

export const PROVIDER_STATUS = {
  Ativo: 0,
  Pausado: 1,
  Inativo: 2,
};

export const statusToText = (
  status: number,
  t: TFunction<"translation", undefined>
) => {
  switch (status) {
    case 0:
      return t("providers.status.active");
    case 1:
      return t("providers.status.paused");
    case 2:
      return t("providers.status.inactive");
    default:
      return "";
  }
};
