import Leads from "pages/leads";
import { Dashboard } from "./Dashboard";
import { useViewmodel } from "./viewModel";
import { PageHeader } from "components/Page/PageHeader";
import { PageFilter } from "components/Page/PageFilter";
import { ConfigModal } from "./components/ConfigModal";

export default function Home() {
  const {
    user,
    t,
    handleCloseConfigModal,
    showConfigModal,
    selectedProvider,
    handleShowConfigModal,
  } = useViewmodel();

  if (user?.role === "seller" && user.isActive) {
    return <Leads />;
  }

  return (
    <div>
      <div className="flex items-center justify-between">
        <PageHeader title={t("dashboard.title", { user: user?.name })} />
        <PageFilter 
          extraButtons={selectedProvider.id !== "admin" ? (
            <button onClick={handleShowConfigModal}>
              <img src="./icons/page/config.svg" className="h-6" alt="Configurações" />
            </button>
          ) : undefined}
        />
      </div>
      {user?.isActive ? (
        <Dashboard />
      ) : (
        <div>
          <p>
            Sua conta foi desativada. Para mais informações entre em contato com
            o suporte
          </p>
        </div>
      )}
      {showConfigModal && <ConfigModal handleClose={handleCloseConfigModal} />}
    </div>
  );
}
