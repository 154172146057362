import { useTranslation } from "react-i18next";
import { DashboardData } from "services/dashboardService";
import { cn } from "utils/styles";

type Props = {
  variant?: "primary" | "secondary";
  data: DashboardData;
};

export const SalesCard = ({
  variant = "primary",
  data,
}: Props) => {
  const { t } = useTranslation();
  
  return (
    <div
      className={cn(
        "rounded-2xl flex flex-col items-center justify-center gap-1 min-h-[7rem] laptop:flex-1 desktop:flex-none desktop-xl:flex-1",
        variant === "primary"
          ? "bg-primaryMultiply/70 px-4 py-4"
          : "border border-white/30 py-2 px-2 desktop-lg:px-4"
      )}
    >
      <div className="flex items-center gap-2.5">
        <img
          className={cn(variant === "primary" ? "" : "h-3.5")}
          src="/icons/campaigns/cart.svg"
          alt=""
        />
        <p className={cn(variant === "primary" ? "" : "text-xs")}>
          {t("dashboard.cards.sells")}
        </p>
      </div>
      <div className="font-input">
        <div className="flex flex-col mb-1">
          <span className="text-xs text-secondary text-center">
            {t("dashboard.cards.total")}
          </span>
          <p
            className={cn(
              "font-bold text-center",
              variant === "primary" ? "text-2xl" : "text-xl"
            )}
          >
            {data.byComputed.sells.total}
          </p>
        </div>
        <div className="flex gap-2">
          <div className="flex flex-col">
            <span className="text-xs text-secondary">
              {t("dashboard.cards.currentPeriod")}
            </span>
            <p
              className={cn(
                "font-bold text-center",
                variant === "primary" ? "text-xl" : "text-lg"
              )}
            >
              {data.byComputed.sells.soldGrouped}
            </p>
          </div>
          <div className="flex flex-col">
            <span className="text-xs text-secondary">
              {t("dashboard.cards.lastPeriod")}
            </span>
            <p
              className={cn(
                "font-bold text-center",
                variant === "primary" ? "text-xl" : "text-lg"
              )}
            >
              {data.byComputed.sells.oldSoldGrouped}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
