import { cn } from "utils/styles";
import { ConvertionCard } from "./ConvertionCard";
import { SalesCard } from "./SalesCard";
import { SalesCostCard } from "./SalesCostCard";
import { type DashboardData  } from "services/dashboardService";
import { RoiCard } from "./RoiCard";

type Props = {
  variant?: "primary" | "secondary";
  data: DashboardData;
};

export const SalesResume = ({
  variant = "primary",
  data
}: Props) => {
  return (
    <div
      className={cn(
        "flex flex-col desktop:flex-row gap-3 desktop:gap-1.5 rounded-2xl",
        variant === "primary" ? "bg-primary/40 p-4" : "flex-1"
      )}
    >
      <div className="flex flex-col laptop:flex-row gap-3 desktop:gap-1.5 desktop-xl:flex-1">
        <SalesCard
          variant={variant}
          data={data}
        />
        <ConvertionCard
          variant={variant}
          data={data}
        />
      </div>
      <div className="flex gap-3 desktop:gap-1.5 flex-1 flex-col laptop:flex-row">
        <SalesCostCard
          variant={variant}
          data={data}
        />
        <RoiCard
          variant={variant}
          data={data}
        />
      </div>
    </div>
  );
};
